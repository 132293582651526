<template>
  <div class="receipt">
    <el-button type="primary" class="save-btn" @click="handleSavePDF">
      Save as PDF
    </el-button>
    <div class="paper" id="show">
      <img src="../assets/receipt.jpg" class="paybackImg" />
      <div class="paystudentBlock first">
        <div>You have successfully paid for the Global Youth Pulses Subscription.</div>
        <div>Please find the details of your payment below.</div>
      </div>

      <div class="paystudentBlock">
        <div class="lineTitle">Student Information</div>
        <div class="txt">
          <span class="title">Name:</span
          >{{
            item.student_info
              ? item.student_info.student_givenName_pinyin +
                " " +
                item.student_info.student_lastName_pinyin
              : ""
          }}
        </div>
        <div class="txt">
          <span class="title">Email:</span
          >{{ item.student_info ? item.student_info.student_email : "" }}
        </div>

        <div class="txt">
          <span class="title">Gender:</span
          >{{
            item.student_info
              ? item.student_info.student_gender === "女"
                ? "Female"
                : "Male"
              : ""
          }}
        </div>
        <div class="txt">
          <span class="title">School:</span
          >{{ item.account_info ? item.account_info.account_nameEN : "" }}
        </div>
      </div>

      <div class="paystudentBlock">
        <div class="lineTitle">Order Information</div>
        <div
          style="margin-top: 10px; border: 1px solid #606266; font-size: 14px"
        >
          <div style="display: flex">
            <div class="leftTable">Order ID</div>
            <div class="rightTable">
              {{
                item.app_info && item.app_info._id ? item.app_info._id.$id : ""
              }}
            </div>
          </div>
          <div style="display: flex">
            <div class="leftTable">Program</div>
            <div class="rightTable">{{ item.project_info.project_ename }}</div>
          </div>
          <div style="display: flex">
            <div class="leftTable">Item</div>
            <div class="rightTable">Subscription Fee</div>
          </div>
          <div style="display: flex">
            <div class="leftTable">Standard Price</div>
            <div class="rightTable">{{ "100 SGD" }}</div>
          </div>
          <div style="display: flex">
            <div class="leftTable">Discount</div>
            <div class="rightTable">
              {{ 100 - item.app_info.total_price + " SGD" }}
            </div>
          </div>
          <div style="display: flex">
            <div class="leftTable">Amount Payable</div>
            <div class="rightTable">
              {{ item.app_info.total_price + " SGD" }}
            </div>
          </div>
        </div>
      </div>
      <div class="paystudentBlock">
        <div class="lineTitle">Payment Information</div>
        <div class="txt">
          <span class="title">Amount Received:</span
          >{{ item.app_info.total_price + " SGD" }}
        </div>
        <div class="txt">
          <span class="title">Payment Method:</span>{{ "Stripe" }}
        </div>
        <div class="txt">
          <span class="title">Payment Time:</span
          >{{ formatDate(item.app_info.application_payment_date) }}
        </div>
        <div class="txt">
          <span class="title">Payment Status:</span>{{ "Success" }}
        </div>
        <div class="txt">
          <span class="title">Transaction ID:</span>{{ formatTransactionID() }}
        </div>
        <div class="txt">
          <span class="title">Amount Refunded:</span>{{ "0" }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPaymentApplication } from "../api/index";
export default {
  data() {
    return {
      application_id: this.$route.query.application_id,
      item: {
        app_info: {},
      },
    };
  },
  mounted() {
    this.requestData();
  },

  methods: {
    formatTransactionID() {
      if (
        this.item.app_info &&
        this.item.app_info.application_paymentintent_id
      ) {
        return this.item.app_info.application_paymentintent_id;
      }
      let json =
        this.item.app_info.stripe && JSON.parse(this.item.app_info.stripe);
      return json && json.payment_intent;
    },

    formatDate(applicatio_time) {
      // let time1 = new Date(applicatio_time)
      // let time = time1.getTime();
      // console.log('time: ' + time)
      var date = new Date(applicatio_time);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return (
        this.formatMonthToEng(month) +
        " " +
        day +
        ", " +
        year +
        ", " +
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":" +
        (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())
      );
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },
    requestData() {
      getPaymentApplication(this.application_id).then((res) => {
        this.item = res.data.data;
      });
    },
    handleSavePDF() {
      if (!this.item) {
        this.$message.error("导出失败");
        return;
      }
      const printData = document.getElementById("show").innerHTML;
      //   window.document.body.innerHTML = printData; // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
      window.print(); // 开始打印
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 14px;
}
.save-btn {
  position: absolute;
  top: 20px;
  right: 50px;
  background-color: #fd6963;
}
.paper {
  //   width: 563px;
  position: relative;
}
.paybackImg {
  position: absolute;
  width: 563px;
  height: auto;
  top: 0;
  left: 0;
  z-index: -1;
}
.first {
  padding-top: 60px !important;
  font-size: 14px;
  line-height: 24px;
}
.paystudentBlock {
  width: 563px;
  padding: 10px 16px;
  color: #262727;

  .lineTitle {
    font-size: 18px;
    font-family: "DDINAlternateRegular";
    font-weight: normal;
    color: #262727;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .title {
    font-weight: bold;
    margin-right: 10px;
  }

  .leftTable {
    padding: 5px 10px;
    width: 40%;
    border-right: 1px solid #606266;
    border-bottom: 1px solid #606266;
  }

  .rightTable {
    padding: 5px 10px;
    width: 60%;
    text-align: right;
    border-bottom: 1px solid #606266;
  }

  .txt {
    margin-top: 10px;
    font-size: 16px;
  }
}
</style>
